import React from "react";
import Button from "../UI/Button";

const RisingStarsPortal = () => {
  return (
    <section className="relative w-full h-[400px] md:h-[500px] bg-[#0B1F2C] overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-[position:75%_center] md:bg-center
        md:bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Middle.png')] 
        bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Middle-Mobile.png')]"
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-[#0B1F2C] via-[#0B1F2C]/60 to-transparent md:to-transparent to-[#0B1F2C]/40" />

      {/* Content Container */}
      <div className="relative z-10 h-full">
        <div className="max-w-[1400px] mx-auto px-6 h-full">
          <div className="flex flex-col justify-center items-center h-full">
            <img
              src="https://gamersupps.s3.us-east-2.amazonaws.com/images/RS-Logo.png"
              alt="Rising Stars Logo"
              className="w-[80%] md:w-[500px] mb-3"
            />
            <p className="text-white text-lg md:text-xl mb-6 font-bold text-center">
              EXPLORE THE CARD GALLERY
            </p>
            <Button
              href="/cards"
              className="bg-[#FBED9F] text-secondary hover:bg-white"
            >
              Explore Gallery
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RisingStarsPortal;
