import React, { useState } from "react";
import Header from "../components/UI/Header";
import ContactHelmet from "../SEO/ContactHelmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    isError: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ message: "", isError: false });

    try {
      const params = new URLSearchParams({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        source: "v-card-website",
      });

      const response = await fetch(
        `https://cupathon.com/nod/api/gorgias/create-ticket?${params.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to send message");

      setSubmitStatus({
        message: "Message sent successfully!",
        isError: false,
      });

      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      setSubmitStatus({
        message: "Failed to send message. Please try again.",
        isError: true,
      });
    }
  };

  return (
    <>
      <ContactHelmet />

      <div id="contact">
        <div className="max-w-2xl mx-auto px-4 py-8 sm:px-6">
          {/* Header Content */}
          <div className="space-y-4 mb-8">
            <Header title="CONTACT US" subHeader="Talk to us!" />

            <p className="text-base text-gray-300">
              To reach us you can fill out the form or contact us using the info
              below.
            </p>

            <div className="space-y-2">
              <div>
                <p className="text-gray-300">
                  For partnership requests/questions, please see our
                  Partnerships page over at
                </p>
                <a
                  href="https://gamersupps.gg/pages/partnership"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#fbed9f] hover:text-yellow-300 transition-colors duration-300"
                >
                  Gamer Supps Partnerships
                </a>
              </div>

              <p className="text-gray-300">
                Email:{" "}
                <a
                  href="mailto:contact@gamersupps.gg"
                  className="text-[#fbed9f] hover:text-yellow-300 transition-colors duration-300"
                >
                  contact@gamersupps.gg
                </a>
              </p>
            </div>
          </div>

          {/* Form */}
          <div className="w-full">
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Name"
                className="w-full px-4 py-3 rounded bg-[#1a2836] border border-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#fbed9f] focus:border-[#fbed9f] transition duration-200"
                required
              />

              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Email Address"
                className="w-full px-4 py-3 rounded bg-[#1a2836] border border-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#fbed9f] focus:border-[#fbed9f] transition duration-200"
                required
              />

              <textarea
                name="message"
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                placeholder="Message"
                rows="6"
                className="w-full px-4 py-3 rounded bg-[#1a2836] border border-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-[#fbed9f] focus:border-[#fbed9f] transition duration-200 resize-none"
                required
              />

              <div className="flex justify-end pt-2">
                <button
                  type="submit"
                  className="px-8 py-2.5 bg-[#fbed9f] text-slate-900 font-bold rounded hover:bg-[#fff] transform transition-all duration-300"
                >
                  SEND MESSAGE
                </button>
              </div>

              {submitStatus.message && (
                <div
                  className={`text-center p-2 rounded ${
                    submitStatus.isError
                      ? "bg-red-500/20 text-red-200"
                      : "bg-green-500/20 text-green-200"
                  }`}
                >
                  {submitStatus.message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
