import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import NewsletterSection from "./NewsletterSection";
function Footer() {
  const location = useLocation();

  return (
    <>
      <NewsletterSection />
      <footer className="footer bg-secondary">
        <div className="footer-content">
          {/* Main Footer Content */}
          <div className="footer-grid">
            {/* Logo & Description */}
            <div className="footer-logo-container">
              <img
                src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Footer-Logo.png"
                alt="VCARD Logo"
                className="footer-logo"
              />
              <p className="footer-description">
                <span className="highlight">VCARD TCG</span> is an entertainment
                trading card game that brings to life the VTubers and famous
                Content Creators from over 50 popular internet idols.
              </p>
            </div>

            {/* Navigation Links */}
            <nav className="footer-nav">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cards"
                    className={location.pathname === "/cards" ? "active" : ""}
                  >
                    Card Gallery
                  </Link>
                </li>
                <li>
                  <Link
                    to="/stars"
                    className={location.pathname === "/stars" ? "active" : ""}
                  >
                    Meet the Stars
                  </Link>
                </li>

                <li>
                  <Link
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>

            {/* Social Links */}
            <div className="footer-social">
              <h3>Follow Us</h3>
              <div className="social-icons">
                <a
                  href="https://instagram.com/gamersupps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Insta-Icon.png"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://facebook.com/GamerSupps.GG"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Facebook-Icon.png"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://gamersupps.gg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://gamersupps.s3.us-east-2.amazonaws.com/images/GS-Icon.png"
                    alt="Gamer Supps"
                  />
                </a>
                <a
                  href="https://x.com/GamerSupps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://gamersupps.s3.us-east-2.amazonaws.com/images/X-Icon.png"
                    alt="X (Twitter)"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Copyright */}
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} GAMER SUPPS. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
