import React from "react";

const Marquee = ({ text }) => {
  return (
    <div className="w-full bg-gradient-to-r from-[#fbed9f] via-[#c5966b] to-[#fbed9f] bg-[length:200%_100%] animate-gradient py-1.5 overflow-hidden">
      <div className="flex justify-center items-center">
        <span className="text-[#102432] text-sm font-bold">{text}</span>
      </div>
    </div>
  );
};

export default Marquee;
