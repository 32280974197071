import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/UI/Header";
import StarGrid from "../components/stars/StarGrid";
import StarModal from "../components/stars/StarModal";
import Tabs from "../components/UI/Tabs";
import creatorsData from "../data/creators.json";
import vtubersData from "../data/vtubers.json";
import StarsHelmet from "../SEO/StarsHelmet";
const STAR_TYPES = {
  VTUBER: "VTUBER",
  CREATOR: "CREATOR",
};

const StarsNew = ({ isNavOpen = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStar, setSelectedStar] = useState(null);
  const [displayStars, setDisplayStars] = useState([]);

  // Get initial type from URL or default to VTUBER
  const getInitialType = () => {
    const path = location.pathname.toLowerCase();
    if (path.includes("creators")) return "CREATOR";
    return "VTUBER";
  };

  const [selectedStarType, setSelectedStarType] = useState(getInitialType);

  // Handle type change and URL update
  const handleTypeChange = (newType) => {
    setSelectedStarType(newType);
    const newPath = newType === "CREATOR" ? "/creators" : "/stars";
    navigate(newPath);
  };

  // Update type if URL changes externally
  useEffect(() => {
    setSelectedStarType(getInitialType());
  }, [location.pathname]);

  // Update displayed stars when type changes
  useEffect(() => {
    const data = selectedStarType === "CREATOR" ? creatorsData : vtubersData;
    const stars = data
      .map((star) => ({
        ...star,
        imageUrl: star.profilePicture,
        isPlaceholder: !star.profilePicture && !star.imageUrl,
        socials: {
          twitter: star.twitter,
          youtube: star.youtube,
          twitch: star.twitch,
        },
      }))
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name

    setDisplayStars(stars);
  }, [selectedStarType]);

  return (
    <>
      <div
        className={`w-full max-w-7xl mx-auto px-4 pb-10 ${
          isNavOpen ? "mt-32" : "mt-8"
        }`}
      >
        <Header title="STARS" />

        <StarsHelmet selectedStarType={selectedStarType} />

        <Tabs
          items={STAR_TYPES}
          selectedType={selectedStarType}
          onTypeChange={handleTypeChange}
          classes="justify-center"
        />

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6 md:gap-8">
          {displayStars.map((star, index) => (
            <StarGrid key={star.id} star={star} onClick={setSelectedStar} />
          ))}
        </div>

        {selectedStar && (
          <StarModal
            star={selectedStar}
            onClose={() => setSelectedStar(null)}
          />
        )}
      </div>
    </>
  );
};

export default StarsNew;
