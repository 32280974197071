import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useState, useEffect } from "react";

const CardRevealsSection = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    loop: false,
    slidesToScroll: 1,
    breakpoints: {
      "(min-width: 768px)": { slidesToScroll: 2 },
      "(min-width: 1024px)": { slidesToScroll: 2 },
    },
  });

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };

    emblaApi.on("select", onSelect);
    onSelect();

    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const cards = [
    {
      src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-001-Shylily_gxxbcc.webp",
      alt: "Shylily Card",
    },
    {
      src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-003-Nanoless_j1bq7w.webp",
      alt: "Nanoless Card",
    },
    {
      src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-004-Sinder_ye51k5+(1).webp",
      alt: "Sinder Card",
    },
    {
      src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-005-CottonTail_da9pqr.webp",
      alt: "Cottontailva Card",
    },
    {
      src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-006-Silvervale_bfncsf.webp",
      alt: "Silvervale Card",
    },
    // {
    //   src: "https://gamersupps.s3.us-east-2.amazonaws.com/VCARD/booster-packs/Booster-Pack-007-Box-Topper_nck6r8.webp",
    //   alt: "Box Topper Card",
    // },
  ];

  return (
    <section className="card-reveals-section border-t border-primary bg-gradient-to-b from-primary to-[#D0A875]">
      <div className="section-grid">
        <div className="text-center">
          <h2 className="text-secondary text-2xl md:text-4xl font-bold">
            VCARD BOOSTER PACKS
          </h2>
          <img
            src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Divide-Dark_q4kfn2.webp"
            alt="Decorative Divider"
            className="mx-auto w-80 my-3 md:my-6 "
            loading="lazy"
          />
        </div>

        <div className="relative">
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="flex">
              {cards.map((card, index) => (
                <div
                  key={index}
                  className="flex-[0_0_50%] min-w-0 md:flex-[0_0_25%] lg:flex-[0_0_20%] pl-4"
                >
                  <img
                    src={card.src}
                    alt={card.alt}
                    className="w-full transform  scale-x-[1.2] scale-y-[1.2] 
                      transition-all duration-300 ease-out
                      hover:scale-[1.25] 
                      hover:drop-shadow-[0_0_15px_rgba(248,228,165,0.5)]
                      "
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          </div>

          {canScrollPrev && (
            <button
              onClick={scrollPrev}
              className="absolute left-4 md:left-8 top-1/2 -translate-y-1/2 bg-secondary text-primary hover:bg-white hover:text-secondary p-3 md:p-4 rounded-full z-10 transition-all duration-300 shadow-lg hover:scale-110 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-5 h-5 md:w-6 md:h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
          )}
          {canScrollNext && (
            <button
              onClick={scrollNext}
              className="absolute right-4 md:right-8 top-1/2 -translate-y-1/2 bg-secondary text-primary hover:bg-white hover:text-secondary p-3 md:p-4 rounded-full z-10 transition-all duration-300 shadow-lg hover:scale-110 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-5 h-5 md:w-6 md:h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default CardRevealsSection;
