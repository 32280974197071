import { Helmet } from "react-helmet-async";

const HomeHelmet = () => {
  return (
    <Helmet>
      <title>Home | VCard Trading Card Game</title>
      <meta
        name="description"
        content="The ultimate trading card game featuring VTubers and Content Creators"
      />
      <meta
        name="keywords"
        content="VCard, Trading Card Game, VTubers, Content Creators, Cards, Collectibles, Gaming, Entertainment"
      />
      <link rel="canonical" href="https://vcardtcg.com" />
      <meta property="og:title" content="Home | VCard Trading Card Game" />
      <meta
        property="og:description"
        content="The ultimate trading card game featuring VTubers and Content Creators"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://vcardtcg.com" />
      <meta
        property="og:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-video-preview:large, max-snippet:150"
      />
    </Helmet>
  );
};

export default HomeHelmet;
