// src/cardInfo/card_types.jsx

export const ALL_CARD_TYPES = "ALL";
//export const SUPPORTS_CARD_TYPE = "SUPPORT";
export const COMMONS_CARD_TYPE = "COMMON";
export const UNCOMMONS_CARD_TYPE = "UNCOMMON";
export const RARES_CARD_TYPE = "RARE";
export const ULTRA_RARES_CARD_TYPE = "ULTRA RARE";
export const EPIC_RARES_TYPE = "EPIC RARE";
export const SECRET_RARES_CARD_TYPE = "SECRET RARE";
export const GOD_RARES_TYPE = "GOD RARE";
export const PROMO_CARD_TYPE = "PROMO";
export const MASCOTS_CARD_TYPE = "MASCOTS";
export const SUPPORT_CARD_TYPE = "SUPPORT";

export const CARD_TYPES = {
  ALL: "ALL",
  MASCOTS: "MASCOTS",
  UNCOMMONS: "UNCOMMONS",
  RARES: "RARES",
  ULTRA_RARES: "ULTRA RARES",
  GOD_RARES: "GOD RARES",
  SUPPORTS: "SUPPORTS",
  SECRET_RARES: "SECRET RARES",
  PROMOS: "PROMOS",
};

export const PLACEHOLDER_CARD_TYPE =
  "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/cards-sorted/Promo/Placeholder-Web.png";
