import { useRef, useEffect } from "react";

const Video = ({
  src,
  poster,
  className = "",
  priority = false,
  loop = true,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // Optimization: Only load video when it's close to viewport
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Start loading the video when it's near viewport
            video.load();
            // Start playing when loaded
            video.play().catch(() => {
              // Autoplay might be blocked by browser
              console.log("Autoplay prevented");
            });
            observer.unobserve(video);
          }
        });
      },
      { rootMargin: "50px" }
    );

    observer.observe(video);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <video
      ref={videoRef}
      className={`w-full h-full object-cover ${className}`}
      playsInline
      muted
      autoPlay={priority} // Only autoplay immediately if priority
      loop={loop}
      poster={poster}
      preload={priority ? "auto" : "none"}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
