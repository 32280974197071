import React, { useState, useEffect } from "react";
import Button from "../UI/Button";
import LoadingVcard from "../UI/LoadingVcard";

const MeetTheStarsPortal = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadImages = async () => {
      const imageUrls = [
        "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom.png",
        "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom-Mobile.png",
      ];

      try {
        await Promise.all(
          imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            });
          })
        );
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadImages();
  }, []);

  return (
    <section className="relative w-full h-[400px] md:h-[500px] bg-[#0B1F2C] overflow-hidden">
      {isLoading && <LoadingVcard />}

      {/* Background Image */}
      <div
        className={`absolute inset-0 bg-cover bg-[position:75%_center] md:bg-right-center transition-opacity duration-500
          md:bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom.png')] 
          bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Bottom-Mobile.png')]
          ${isLoading ? "opacity-0" : "opacity-100"}`}
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-tertiary via-[#0B1F2C]/60 to-transparent md:to-transparent to-[#0B1F2C]/40" />

      {/* Content Container */}
      <div
        className={`relative z-10 h-full transition-opacity duration-500 ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="max-w-[1400px] mx-auto px-6 h-full">
          <div className="flex flex-col justify-center h-full max-w-lg mx-auto md:mx-0">
            {/* Text Content */}
            <div className="space-y-6 text-center md:text-left">
              <div>
                <h3 className="text-[#FBED9F] text-2xl md:text-3xl font-bold mb-2">
                  MEET THE
                </h3>
                <h2 className="text-white text-6xl md:text-8xl font-bold mb-4">
                  STARS
                </h2>
                <img
                  src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png"
                  alt="Divider"
                  className="w-48 md:w-80 mb-4 mx-auto md:mx-0"
                />
              </div>

              <div>
                <Button
                  href="/stars"
                  className="bg-[#FBED9F] text-secondary hover:bg-white px-10 md:px-12 py-3 text-lg md:text-xl"
                >
                  LEARN MORE
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetTheStarsPortal;
