import React, { useState } from "react";
import StarSkeleton from "./StarSkeleton";
import { FaYoutube, FaTwitch } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
const SocialLinks = ({ twitter, youtube, twitch }) => (
  <div className="social-media-buttons flex justify-center items-center gap-2">
    {twitter && (
      <a
        href={twitter}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-primary"
      >
        <BsTwitterX />
      </a>
    )}
    {youtube && (
      <a
        href={youtube}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-primary"
      >
        <FaYoutube />
      </a>
    )}
    {twitch && (
      <a
        href={twitch}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white hover:text-primary"
      >
        <FaTwitch />
      </a>
    )}
  </div>
);

const StarGrid = ({ star, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className="calendar-card-container cursor-pointer mb-6"
      onClick={() => onClick(star)}
    >
      <div
        className="calendar-card cursor-pointer"
        onClick={() => onClick(star)}
      >
        <div className="calendar-card-inner">
          <div className="calendar-card-front">
            {!imageLoaded && <StarSkeleton />}
            <img
              src={star.imageUrl}
              alt={star.name}
              className={`calendar-card-image ${
                !imageLoaded ? "opacity-0" : "opacity-100"
              }`}
              onLoad={() => setImageLoaded(true)}
              loading="eager"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                transition: "opacity 0.3s ease-in-out",
                clipPath:
                  "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>
      <div className="calendar-card-info">
        <h3 className="text-white text-sm font-bold mb-1 text-center">
          {star.name}
        </h3>
        <SocialLinks
          twitter={star.socials?.twitter}
          youtube={star.socials?.youtube}
          twitch={star.socials?.twitch}
        />
      </div>
    </div>
  );
};

export default StarGrid;
