import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import Marquee from "../Marquee";
import { FaBars } from "react-icons/fa";

const navigationLinks = [
  { path: "/", label: "HOME" },
  { path: "/cards", label: "CARD GALLERY" },
  { path: "/stars", label: "MEET THE STARS", includeCreators: true },
  { path: "/contact", label: "CONTACT US" },
];

const Navigation = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActivePath = (path, includeCreators = false) => {
    if (includeCreators) {
      return (
        location.pathname.includes(path) ||
        location.pathname.includes("/creators")
      );
    }
    return location.pathname === path;
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="sticky top-0 left-0 right-0 z-50">
      <Marquee text="VCARD LAUNCHING SOON! GET READY!" />
      <div
        className={`w-full transition-all duration-500 ease-in-out ${"bg-secondary/70 backdrop-blur"}`}
      >
        <nav className="border-gray-200 transition-colors duration-300 text-white">
          <div className="container mx-auto max-w-screen-xl px-4 py-3">
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="flex-shrink-0 w-40 md:w-48 relative z-50 cursor-pointer"
                aria-label="Home"
              >
                <img
                  src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
                  alt="VCARD Logo"
                  className="h-auto w-full"
                  loading="eager"
                />
              </Link>
              <div className="hidden lg:flex flex-grow justify-center max-w-2xl">
                <div className="flex items-center justify-center space-x-10">
                  {navigationLinks.map((link) => (
                    <Link
                      key={link.path}
                      to={link.path}
                      className={`nav-link relative font-semibold ${
                        isActivePath(link.path, link.includeCreators)
                          ? "text-[#fbed9f]"
                          : "text-white"
                      } hover:text-[#fbed9f] transition-colors duration-300 after:content-[''] after:absolute after:w-full after:h-[2px] after:bg-[#fbed9f] after:bottom-0 after:left-0 after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300 after:origin-center`}
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-end flex-shrink-0 w-40 md:w-48">
                <Link
                  to="https://gamersupps.gg/pages/vcard"
                  target="_blank"
                  className="hidden lg:inline-block relative px-6 py-2.5 font-bold text-navy-900 rounded overflow-hidden group hover:shadow-[0_0_20px_0_rgba(251,237,159,0.5)] transition-shadow duration-300"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-[#fbed9f] to-[#c5966b] transition-transform duration-300 ease-out group-hover:scale-105"></div>
                  <div className="absolute inset-0 bg-gradient-to-r from-[#c5966b] to-[#fbed9f] opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100"></div>
                  <span className="relative z-10">GET STARTED!</span>
                </Link>
                <button
                  className="lg:hidden p-2 text-white hover:text-[#fbed9f] transition-colors duration-300"
                  onClick={toggleMenu}
                >
                  <FaBars className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <HamburgerMenu
        isOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        navigationLinks={navigationLinks}
      />
    </header>
  );
};

export default Navigation;
