import React from "react";

const Button = ({ href, children, className = "", onClick, target }) => {
  const baseClasses = `
    inline-block px-8 py-3 text-navy-900 font-bold rounded-md
   transform hover:scale-105 transition-all duration-300
    
    ${className}
  `;

  if (href) {
    return (
      <a
        href={href}
        target={target || "_self"}
        rel="noopener noreferrer"
        className={baseClasses}
      >
        {children}
      </a>
    );
  }

  return (
    <button onClick={onClick} className={baseClasses}>
      {children}
    </button>
  );
};

export default Button;
