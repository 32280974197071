const Header = ({ title, subHeader }) => {
  return (
    <div className="text-center ">
      <h3 className="text-xl text-primary font-bold mb-2">
        {subHeader || "Explore the"}
      </h3>
      <h1 className="text-4xl lg:text-6xl  font-bold text-white mb-4">
        {title}
      </h1>
      <img
        src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png"
        alt="Decorative Divide"
        className="mx-auto w-1/2"
      />
    </div>
  );
};

export default Header;
