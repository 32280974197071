import React, { useEffect } from "react";
import { FaYoutube, FaTwitch } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const ELEMENT_STYLES = {
  water: {
    background: "bg-gradient-to-br from-blue-600/80 to-cyan-800/80",
    text: "text-blue-200",
  },
  grass: {
    background: "bg-gradient-to-br from-green-900/80 to-emerald-800/80",
    text: "text-green-200",
  },
  platinum: {
    background: "bg-gradient-to-br from-slate-800/80 to-zinc-700/80",
    text: "text-slate-200",
  },
  fire: {
    background: "bg-gradient-to-br from-red-900/80 to-orange-800/80",
    text: "text-red-200",
  },
  electric: {
    background: "bg-gradient-to-br from-yellow-500/60 to-yellow-300/80",
    text: "text-yellow-200",
  },
  support: {
    background: "bg-gradient-to-br from-purple-900/80 to-fuchsia-800/80",
    text: "text-purple-200",
  },
};

const StarModal = ({ star, onClose }) => {
  const elementStyle =
    ELEMENT_STYLES[star.element?.toLowerCase()] || ELEMENT_STYLES.support;

  // Disable scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className={`relative w-full max-w-2xl rounded-lg shadow-xl ${elementStyle.background} p-6 backdrop-blur-md`}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white/80 hover:text-white"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex flex-col md:flex-row gap-6">
          {/* Image */}
          <div className="w-full md:w-1/2">
            <img
              src={star.profilePicture}
              alt={star.name}
              className="w-full h-auto rounded-lg object-cover"
            />
          </div>

          {/* Content */}
          <div className="w-full md:w-1/2 text-white">
            <h2 className="text-2xl font-bold mb-4">{star.name}</h2>

            {/* Element Badge */}
            <div
              className={`inline-block px-3 py-1 rounded-full ${elementStyle.text} bg-black/20 mb-4 capitalize`}
            >
              {star.element}
            </div>

            {/* Social Links with Icons */}
            <div className="flex gap-6 mt-4">
              {star.twitter && (
                <a
                  href={star.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors flex items-center gap-2"
                >
                  <BsTwitterX className="w-5 h-5" />
                  <span className="hidden md:inline">Twitter</span>
                </a>
              )}
              {star.youtube && (
                <a
                  href={star.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors flex items-center gap-2"
                >
                  <FaYoutube className="w-5 h-5" />
                  <span className="hidden md:inline">YouTube</span>
                </a>
              )}
              {star.twitch && (
                <a
                  href={star.twitch}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors flex items-center gap-2"
                >
                  <FaTwitch className="w-5 h-5" />
                  <span className="hidden md:inline">Twitch</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StarModal;
