// App.js
import React, { useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Footer from "./components/UI/Footer/Footer";
import StarsNew from "./views/StarsNew";
import "./App.css";
import CardGalleryNew from "./views/CardGalleryNew";
import Navigation from "./components/UI/Navigation/Navigation";
import NotFound from "./components/UI/NotFound";
import Contact from "./views/Contact";
import Home from "./views/Home";
function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

function App() {
  const appScrollRef = useRef(null);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <div ref={appScrollRef} className="relative">
          <Navigation />
          <div className="min-h-screen overflow-hidden">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/creators" element={<StarsNew />} />
              <Route path="/stars" element={<StarsNew />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cards" element={<CardGalleryNew />} />
              <Route path="/cards/:type" element={<CardGalleryNew />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </HelmetProvider>
  );
}

function ScrollToTop() {
  useScrollToTop();
  return null;
}

export default App;
