const PLACEHOLDER_IMAGE =
  "https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/cards-sorted/Promo/Placeholder-Web.png";

const CardSkeleton = () => (
  <div className="aspect-[3/4] relative">
    <img
      src={PLACEHOLDER_IMAGE}
      alt="Loading..."
      className="w-full h-full object-cover rounded-lg opacity-50 transition-opacity duration-300"
    />
  </div>
);

export default CardSkeleton;
