import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useState, useEffect } from "react";

import Header from "../UI/Header";
const ProductCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    loop: false,
    slidesToScroll: 1,
    breakpoints: {
      "(min-width: 768px)": { slidesToScroll: 2 },
      "(min-width: 1024px)": { slidesToScroll: 3 },
    },
    dragFree: false,
    containScroll: "trimSnaps",
  });

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const products = [
    {
      id: 1,
      name: "Collection Binder",
      image:
        "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/BINDER_HIDDEN_DARKER.png",
      description: "",
      price: "$29.99",
      buttonText: "Learn More",
      buttonLink: "",
    },
    {
      id: 2,
      name: "Rising Stars 1st Edition Booster Box",
      image:
        "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/BOOSTER+BOX_HIDDEN_DARKER.png",
      description: "",
      price: "$9.99",
      buttonText: "Learn More",
      buttonLink: "",
    },
    {
      id: 3,
      name: "Collectible Deck Box",
      image:
        "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/DECKBOX_1_HIDDEN_DARKER.png",
      description: "",
      price: "$49.99",
      buttonText: "Learn More",
      buttonLink: "",
    },
    {
      id: 4,
      name: "VCard Sleeves",
      image:
        "https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/SLEEVES_1_HIDDEN_DARKER.png",
      description: "",
      price: "$49.99",
      buttonText: "Learn More",
      buttonLink: "",
    },
  ];

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };

    emblaApi.on("select", onSelect);
    onSelect();

    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="relative max-w-[1400px] mx-auto px-6 my-10">
      <Header title="COMING SOON" subHeader="VCard Products" />
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {products.map((product) => (
            <div
              key={product.id}
              className="flex-[0_0_100%] min-w-0 md:flex-[0_0_50%] lg:flex-[0_0_33.333%] p-4 "
            >
              <div className=" rounded-lg p-6 text-center bg-[#0B1F2C]/80">
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-48 object-contain mb-4"
                />
                <h3 className="text-primary text-xl font-bold mb-2">
                  {product.name}
                </h3>
                <p className="text-white mb-4">{product.description}</p>
                {/* <p className="text-[#FBED9F] text-2xl font-bold mb-4">
                  {product.price}
                </p> */}
                {/* <a
                  href={product.buttonLink}
                  className="inline-block bg-primary text-secondary hover:bg-white px-8 py-2 rounded-md font-bold transition-colors"
                >
                  {product.buttonText}
                </a> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      {canScrollPrev && (
        <button
          onClick={scrollPrev}
          className="absolute left-4 md:left-8 top-[50%] -translate-y-[-50%] bg-secondary text-primary hover:bg-white hover:text-secondary p-3 md:p-4 rounded-full z-10 transition-all duration-300 shadow-lg hover:scale-110 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-5 h-5 md:w-6 md:h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
      )}
      {canScrollNext && (
        <button
          onClick={scrollNext}
          className="absolute right-4 md:right-8 top-[50%] -translate-y-[-50%] bg-secondary text-primary hover:bg-white hover:text-secondary p-3 md:p-4 rounded-full z-10 transition-all duration-300 shadow-lg hover:scale-110 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-5 h-5 md:w-6 md:h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default ProductCarousel;
