import React from "react";
import logo from "../../assets/images/Nav-Logo.png";

const LoadingVcard = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#102432] transition-opacity duration-500 opacity-100">
      <div className="relative flex flex-col items-center justify-center h-screen w-screen">
        {/* Main logo container with float animation */}
        <div className="relative w-80 h-80 flex items-center justify-center animate-float">
          {/* Logo with shine effect */}
          <div className="relative overflow-hidden rounded-full w-full h-full flex items-center justify-center">
            <img
              src={logo}
              alt="VCARD Logo"
              className="w-4/5 h-4/5 object-contain z-10 relative"
              loading="eager"
            />
            {/* Animated shine overlay */}
            <div className="absolute top-0 -left-full w-full h-full bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shine" />
          </div>

          {/* Spinning outer ring */}
          <div className="absolute inset-0 border-4 border-[#fbed9f]/30 rounded-full animate-[spin_4s_linear_infinite]" />

          {/* Counter-spinning inner ring */}
          <div className="absolute inset-4 border-4 border-[#fbed9f]/20 rounded-full animate-[spin_3s_linear_infinite_reverse]" />
        </div>

        {/* Loading text with pulse animation */}
        <div className="mt-8 text-[#fbed9f] font-['Gill_Sans_Heavy'] text-2xl animate-pulse">
          Loading...
        </div>
      </div>
    </div>
  );
};

export default LoadingVcard;
