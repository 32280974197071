import React from "react";
import { Link, useLocation } from "react-router-dom";

const HamburgerMenu = ({ isOpen, toggleMenu, navigationLinks }) => {
  const location = useLocation();

  const isActivePath = (path, includeCreators = false) => {
    if (includeCreators) {
      return (
        location.pathname.includes(path) ||
        location.pathname.includes("/creators")
      );
    }
    return location.pathname === path;
  };

  return (
    <>
      {/* Backdrop with fade animation */}
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-500 ease-in-out z-40 ${
          isOpen ? "opacity-50" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleMenu}
      />

      {/* Menu with slide animation */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-secondary shadow-lg transform transition-transform duration-500 ease-in-out z-50 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="p-4 border-b border-navy-800 flex justify-between items-center">
            <h2 className="text-xl font-bold text-white">Menu</h2>
            <button
              onClick={toggleMenu}
              className="text-2xl text-white hover:text-primary transition-colors duration-200 transform hover:scale-110"
            >
              ×
            </button>
          </div>

          {/* Navigation Links */}
          <nav className="flex-grow overflow-y-auto">
            <ul className="py-4">
              {navigationLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className={`block py-3 px-4 text-white hover:bg-navy-800 transition-all duration-200 font-bold ${
                      isActivePath(link.path, link.includeCreators)
                        ? "text-primary border-l-4 border-primary pl-3"
                        : "border-l-4 border-transparent"
                    }`}
                    onClick={toggleMenu}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Footer Button */}
          <div className="p-4 border-t border-navy-800">
            <Link
              to="https://gamersupps.gg/pages/vcard"
              className="block w-full text-center py-2.5 px-4 bg-primary text-navy-900 font-bold rounded hover:bg-gold-600 transition-all duration-200 transform hover:scale-105 hover:shadow-lg"
              onClick={toggleMenu}
            >
              Get Started!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HamburgerMenu;
