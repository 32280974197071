import { Helmet } from "react-helmet-async";

const ContactHelmet = () => {
  return (
    <Helmet>
      <title>Contact Us | VCard Trading Card Game</title>
      <meta
        name="description"
        content="Get in touch with the VCard team. Whether you have questions about partnerships, general inquiries, or need support, we're here to help."
      />
      <meta
        name="keywords"
        content="VCard, trading card game, contact, support, partnerships, TCG"
      />

      {/* Open Graph / Social Media */}
      <meta property="og:title" content="Contact VCard Trading Card Game" />
      <meta
        property="og:description"
        content="Get in touch with the VCard team. We're here to help with any questions or inquiries."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://vcardtcg.com/contact" />
      <meta
        property="og:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact VCard Trading Card Game" />
      <meta
        name="twitter:description"
        content="Get in touch with the VCard team. We're here to help with any questions or inquiries."
      />
      <meta
        name="twitter:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Additional SEO */}
      <link rel="canonical" href="https://vcardtcg.com/contact" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default ContactHelmet;
