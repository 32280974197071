import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="min-h-[80vh] flex items-center justify-center bg-[#112433] px-4">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-[#fbed9f] mb-4">404</h1>
        <div className="relative">
          <img
            src="https://gamersupps.s3.us-east-2.amazonaws.com/images/Divide-Gold.png"
            alt="Divider"
            className="w-48 mx-auto my-6"
          />
        </div>
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-8">
          Page Not Found
        </h2>
        <p className="text-gray-400 text-lg mb-8 max-w-md mx-auto">
          Oops! Looks like this card hasn't been revealed yet. Let's get you
          back to the collection.
        </p>
        <Link
          to="/"
          className="inline-block relative px-8 py-3 font-bold text-navy-900 rounded overflow-hidden group hover:shadow-[0_0_20px_0_rgba(251,237,159,0.5)] transition-shadow duration-300"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#fbed9f] to-[#c5966b] transition-transform duration-300 ease-out group-hover:scale-105"></div>
          <div className="absolute inset-0 bg-gradient-to-r from-[#c5966b] to-[#fbed9f] opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100"></div>
          <span className="relative z-10">RETURN HOME</span>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
