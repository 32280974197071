import React from "react";
import "./Launch.css";
const LaunchSection = () => (
  <section className="w-full bg-gradient-to-b from-primary to-[#D0A875] py-14">
    <div className="max-w-[1400px] mx-auto px-4">
      <div className="flex flex-col items-center ">
        <h2 className="text-2xl md:text-4xl font-bold text-secondary mb-3 md:mb-6 text-center">
          LAUNCHING JANUARY 31ST, 2025!
        </h2>

        <div className="mb-2">
          <img
            src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Divide-Dark_q4kfn2.webp"
            className="w-80 h-auto"
            alt="Divider"
            loading="eager"
          />
        </div>

        <p className="text-secondary text-md lg:text-lg max-w-3xl mx-auto leading-relaxed md:text-center">
          VCard isn't just a trading card game—it's a groundbreaking platform
          that transforms your favorite content creators into a vibrant,
          collectible universe! With stunning artwork and a mission to champion
          real artists, VCard delivers an immersive experience that goes beyond
          gaming, celebrating creativity and fandom like never before.
        </p>
      </div>
    </div>
  </section>
);

export default LaunchSection;
