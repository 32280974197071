import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
const StarsHelmet = ({ selectedStarType }) => {
  const location = useLocation();
  // Get page title and description based on selected type
  const getMetaContent = () => {
    if (selectedStarType === "CREATOR") {
      return {
        title: "Content Creators | VCard Trading Card Game",
        description:
          "Discover our amazing lineup of Content Creators featured in the VCard Trading Card Game. From streamers to YouTubers, explore the personalities behind the cards.",
      };
    }
    return {
      title: "VTubers | VCard Trading Card Game",
      description:
        "Meet the incredible VTubers featured in the VCard Trading Card Game. Explore our collection of virtual talents and their unique cards.",
    };
  };

  const metaContent = getMetaContent();
  return (
    <Helmet>
      <title>{metaContent.title}</title>
      <meta name="description" content={metaContent.description} />
      <meta
        name="keywords"
        content="VCard, trading card game, VTubers, content creators, TCG, collectible cards"
      />

      {/* Open Graph / Social Media */}
      <meta property="og:title" content={metaContent.title} />
      <meta property="og:description" content={metaContent.description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://vcardtcg.com${location.pathname}`}
      />
      <meta
        property="og:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaContent.title} />
      <meta name="twitter:description" content={metaContent.description} />
      <meta
        name="twitter:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Additional SEO */}
      <link rel="canonical" href={`https://vcardtcg.com${location.pathname}`} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default StarsHelmet;
