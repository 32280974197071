import { Helmet } from "react-helmet-async";

const CardsGalleryHelmet = ({ selectedCardType }) => {
  // Get metadata based on selected card type
  const getMetaContent = () => {
    const baseTitle = "VCard Trading Card Game";
    const typeTitle =
      selectedCardType === "ALL"
        ? "Complete Card Collection"
        : `${
            selectedCardType.charAt(0) + selectedCardType.slice(1).toLowerCase()
          } Cards`;

    return {
      title: `${typeTitle} | ${baseTitle}`,
      description: `Explore our ${selectedCardType.toLowerCase()} cards in the VCard Trading Card Game. Browse through our extensive collection featuring unique designs, different rarities, and special editions.`,
    };
  };

  const metaContent = getMetaContent();
  return (
    <Helmet>
      <title>{metaContent.title}</title>
      <meta name="description" content={metaContent.description} />
      <meta
        name="keywords"
        content="VCard, trading card game, card gallery, TCG cards, VTuber cards, collectible cards, rare cards, digital cards"
      />

      {/* Open Graph / Social Media */}
      <meta property="og:title" content={metaContent.title} />
      <meta property="og:description" content={metaContent.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://vcardtcg.com/cards" />
      <meta
        property="og:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaContent.title} />
      <meta name="twitter:description" content={metaContent.description} />
      <meta
        name="twitter:image"
        content="https://gamersupps.s3.us-east-2.amazonaws.com/images/Nav-Logo.png"
      />

      {/* Additional SEO */}
      <link rel="canonical" href="https://vcardtcg.com/cards" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default CardsGalleryHelmet;
