import React from "react";

const NavigationButton = ({ direction, onClick, disabled }) => (
  <button
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    disabled={disabled}
    className={`
      absolute top-1/2 -translate-y-1/2
      ${direction === "prev" ? "left-2 md:-left-20" : "right-2 md:-right-20"}
      w-10 h-10 md:w-16 md:h-16 flex items-center justify-center
      bg-black/50 rounded-full
      transition-all duration-200
      ${disabled ? "hidden" : "hover:bg-black/70"}
      z-20
    `}
    aria-label={direction === "prev" ? "Previous card" : "Next card"}
  >
    <span className="text-white/90 text-xl md:text-2xl">
      {direction === "prev" ? "←" : "→"}
    </span>
  </button>
);

const CardModal = ({ card, onClose, onPrev, onNext, isFirst, isLast }) => (
  <div
    className="fixed inset-0 bg-black/75 flex items-center justify-center p-2 md:p-4 z-50"
    onClick={onClose}
  >
    {/* Wrapper div for card and navigation */}
    <div
      className="relative w-full max-w-[95vw] md:max-w-4xl px-2 md:px-20"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="relative w-full bg-navy-900 rounded-xl p-4 md:p-10"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="absolute top-4 md:top-6 right-4 md:right-6 w-8 h-8 flex items-center justify-center rounded-full bg-navy-800 text-gray-400 hover:text-white hover:bg-navy-700 transition-colors z-30"
        >
          ×
        </button>

        <div className="p-2 md:p-4">
          <img
            src={card.url}
            alt="Enlarged Card"
            className="w-full h-auto rounded-lg"
          />
        </div>
      </div>

      {/* Navigation Buttons */}
      <NavigationButton direction="prev" onClick={onPrev} disabled={isFirst} />
      <NavigationButton direction="next" onClick={onNext} disabled={isLast} />
    </div>
  </div>
);

export default CardModal;
