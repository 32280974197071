// Individual Card component with loading state

import { useState } from "react";
import CardSkeleton from "./CardSkeleton";

const Card = ({ card, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="aspect-[3/4] relative group">
      {isLoading && <CardSkeleton />}
      <img
        src={card.url}
        alt="Card"
        className={`w-full h-full object-cover rounded-lg transition-all duration-300
            ${isLoading ? "opacity-0" : "opacity-100"}
            group-hover:scale-105 cursor-pointer`}
        onLoad={() => setIsLoading(false)}
        loading="eager"
        onClick={() => onClick(card)}
      />
    </div>
  );
};

export default Card;
