import React from "react";
import Button from "../UI/Button";

const WelcomeToVCard = () => {
  return (
    <section className="relative w-full h-[400px] md:h-[500px] bg-[#0B1F2C] overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-[position:75%_center] md:bg-right-center
        md:bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Header.png')] 
        bg-[url('https://gamersupps.s3.us-east-2.amazonaws.com/images/vcard/banners/Home-Banner-Header-Mobile.png')]"
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-secondary/100 via-secondary/100 to-secondary/75  md:to-transparent " />
      {/* Content Container */}
      <div className="relative z-10 h-full">
        <div className="max-w-[1400px] mx-auto px-6 h-full">
          <div className="flex flex-col justify-center h-full max-w-lg mx-auto md:mx-0">
            {/* Text Content */}
            <div className="space-y-6 text-center md:text-left">
              <div>
                <h3 className="text-[#FBED9F] text-2xl md:text-3xl font-bold ">
                  WELCOME TO
                </h3>
                <h2 className="text-white text-6xl md:text-8xl font-bold mb-2">
                  VCARD
                </h2>
                <img
                  src="https://gamersupps.s3.us-east-2.amazonaws.com/Vcards-assets/Small-Divide-Gold_yzlfnx.webp"
                  alt="Divider"
                  className="w-48 md:w-80 mx-auto  md:mx-0 mb-1 md:ml-[-10px] lg:ml-[-20px] "
                />
                <p className="text-white text-lg md:text-xl mb-6 font-medium max-w-[350px] md:max-w-none mx-auto md:mx-0 leading-relaxed">
                  Collect, trade, and play with digital trading cards featuring
                  your favorite content creators.
                </p>
              </div>

              <div>
                <Button
                  href="https://gamersupps.gg/collections/vcard-collection"
                  className="bg-[#FBED9F] text-secondary hover:bg-white  "
                  target="_blank"
                >
                  EXPLORE NOW
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeToVCard;
