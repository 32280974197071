import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";

const Tabs = ({ items, selectedType, onTypeChange, classes }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    dragFree: true,
  });

  // Scroll to selected tab when it changes or component mounts
  useEffect(() => {
    if (emblaApi) {
      // Find the index of the selected tab
      const selectedIndex = Object.keys(items).findIndex(
        (key) => key === selectedType
      );
      if (selectedIndex !== -1) {
        // Add a small delay to ensure the carousel is ready
        setTimeout(() => {
          emblaApi.scrollTo(selectedIndex);
        }, 100);
      }
    }
  }, [selectedType, emblaApi, items]);

  return (
    <div className="w-full bg-navy-950 my-6">
      <div className="max-w-[1400px] mx-auto px-2">
        <div className="overflow-hidden" ref={emblaRef}>
          <div className={`flex ${classes} `}>
            {Object.entries(items).map(([key, label]) => (
              <div key={key} className="flex-none mr-1 last:mr-0">
                <button
                  onClick={() => onTypeChange(key)}
                  className={`
                    whitespace-nowrap px-6 py-3 text-sm font-bold tracking-wider uppercase
                    transition-colors duration-200
                    touch-manipulation
                    active:scale-95
                    ${
                      selectedType === key
                        ? "bg-[#F8E4A5] text-navy-900"
                        : "bg-[#0A1522] text-gray-300 hover:bg-[#132236] active:bg-[#132236]"
                    }
                  `}
                >
                  {label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
