import React, { useState, useEffect, useMemo } from "react";
import Card from "../components/Cards/Card";
import CardModal from "../components/Cards/CardModal";
import Header from "../components/UI/Header";
import Tabs from "../components/UI/Tabs";
import cardsData from "../data/cardsData.json";
import CardsGalleryHelmet from "../SEO/CardsGalleryHelmet";
import { useNavigate, useLocation } from "react-router-dom";
import { CARD_TYPES, PLACEHOLDER_CARD_TYPE } from "../cardInfo/card_types";

const CardGallery = ({ isNavOpen = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCard, setSelectedCard] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Get initial type from URL or default to ALL
  const getInitialType = () => {
    const path = location.pathname.toLowerCase();
    // Handle multi-word types first
    if (path.includes("ultra-rares")) return "ULTRA_RARES";
    if (path.includes("god-rares")) return "GOD_RARES";
    if (path.includes("secret-rares")) return "SECRET_RARES";

    // Then handle single-word types
    for (const [key, value] of Object.entries(CARD_TYPES)) {
      if (path.includes(key.toLowerCase().replace("_", "-"))) {
        return value;
      }
    }
    return "ALL";
  };
  const [selectedCardType, setSelectedCardType] = useState(getInitialType);

  // Handle type change and URL update
  const handleTypeChange = (newType) => {
    // Set the state immediately
    setSelectedCardType(newType);

    // Format URL path
    const pathType =
      newType === "ALL"
        ? "cards"
        : `cards/${newType.toLowerCase().replace("_", "-")}`;

    navigate(`/${pathType}`);
  };

  const displayCards = useMemo(() => {
    let filteredCards = [];

    if (selectedCardType === "ALL") {
      filteredCards = Object.entries(cardsData).flatMap(([type, cards]) =>
        cards.map((card) => ({
          ...card,
          cardType: type,
        }))
      );
    } else {
      filteredCards = cardsData[selectedCardType] || [];
    }

    // If no cards found, add placeholders
    if (filteredCards.length === 0) {
      filteredCards = createPlaceholders();
    }

    return filteredCards;
  }, [selectedCardType]);

  // Scroll to top handler
  useEffect(() => {
    const handleScroll = () => setShowScrollButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handlePrevCard = () => {
    const currentIndex = displayCards.findIndex(
      (c) => c.url === selectedCard.url
    );
    if (currentIndex > 0) {
      setSelectedCard(displayCards[currentIndex - 1]);
    }
  };

  const handleNextCard = () => {
    const currentIndex = displayCards.findIndex(
      (c) => c.url === selectedCard.url
    );
    if (currentIndex < displayCards.length - 1) {
      setSelectedCard(displayCards[currentIndex + 1]);
    }
  };

  return (
    <>
      <CardsGalleryHelmet selectedCardType={selectedCardType} />
      <div
        className={`w-full max-w-7xl mx-auto px-4 pb-10 ${
          isNavOpen ? "mt-32" : "mt-8"
        }`}
      >
        {showScrollButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 w-12 h-12 flex items-center justify-center bg-primary hover:bg-gold-600 text-secondary rounded-full shadow-lg transition-all duration-300 z-50"
            aria-label="Jump to top"
          >
            ↑
          </button>
        )}

        <Header title="CARD GALLERY" />

        <Tabs
          items={CARD_TYPES}
          selectedType={selectedCardType}
          onTypeChange={handleTypeChange}
          classes="justify-start lg:justify-center"
        />

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {displayCards.map((card, index) => (
            <Card
              key={`${card.cardType}-${index}`}
              card={card}
              onClick={setSelectedCard}
            />
          ))}
        </div>

        {selectedCard && (
          <CardModal
            card={selectedCard}
            onClose={() => setSelectedCard(null)}
            onPrev={handlePrevCard}
            onNext={handleNextCard}
            isFirst={
              displayCards.findIndex((c) => c.url === selectedCard.url) === 0
            }
            isLast={
              displayCards.findIndex((c) => c.url === selectedCard.url) ===
              displayCards.length - 1
            }
          />
        )}
      </div>
    </>
  );
};

export default CardGallery;

const createPlaceholders = (count = 42) => {
  return Array(count)
    .fill(null)
    .map((_, index) => ({
      url: PLACEHOLDER_CARD_TYPE,
      cardType: "PLACEHOLDER",
      name: "Coming Soon",
      isPlaceholder: true,
      id: `placeholder-${index}`,
    }));
};
