import React from "react";

const StarSkeleton = () => {
  return (
    <div className="calendar-card-container">
      <div className="calendar-card">
        <div className="calendar-card-inner">
          <div className="calendar-card-front flex items-center justify-center">
            <div
              className="w-full aspect-[2/3] relative"
              style={{
                clipPath:
                  "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                background: "linear-gradient(45deg, #0A1522, #132236)",
              }}
            >
              <div className="absolute inset-0">
                <div className="h-full w-full animate-pulse bg-gradient-to-r from-navy-800 via-navy-700 to-navy-800" />
                <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-navy-600/20 to-transparent" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="calendar-card-info mt-3">
        <div className="h-5 w-24 bg-navy-800 rounded animate-pulse mx-auto" />
        <div className="social-media-buttons mt-2 flex justify-center gap-4">
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="h-4 w-4 bg-navy-800 rounded-full animate-pulse"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StarSkeleton;
