import React from "react";
import GameplaySection from "../components/home/GameplaySection";
import LaunchSection from "../components/home/LaunchSection";
import CardRevealsSection from "../components/home/CardRevealSection";
import MeetTheStarsPortal from "../components/home/MeetTheStarsPortal";
import Video from "../components/UI/Video";
import WelcomeToVCard from "../components/home/WelcomeToVCard";
import FeatureProducts from "../components/UI/FeatureProducts";
import RisingStarsPortal from "../components/home/RisingStarsPortal";
import HomeHelmet from "../SEO/HomeHelmet";
const Home = () => {
  return (
    <div>
      <HomeHelmet />
      <WelcomeToVCard />
      <LaunchSection />
      <FeatureProducts />
      <RisingStarsPortal />
      <Video src="https://gamersupps.s3.us-east-2.amazonaws.com/VCard_Animated_Trimmed.mp4" />
      <GameplaySection />
      <CardRevealsSection />
      <MeetTheStarsPortal />
    </div>
  );
};

export default Home;
